/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <ur-page-container title="라이프맵핑 저장" :show-title="true" type="popup">
    <!-- 라이프맴핑 전체 S -->
    <ur-box-container direction="column" alignV="start" class="nw-qw-wrap nw-life-wrap pal0">
      <!-- 라이프맴핑_라이프맵핑 저장 S -->
      <ur-box-container direction="column" alignV="start" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="sch-box ns-add-area">
           <span class="title must sm">파일명</span>
          <mo-validate-watcher>
            <mo-text-field ref="lifeMappFileNm" v-model="ctLifeMappRsltInfoSVO.lifeMappFileNm" :rules="validateLifeMappFileNm" underline placeholder="파일명을 입력하세요." class="form-input-name" maxlength='30' />
          </mo-validate-watcher>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_close">취소</mo-button>
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_fileNmDupChk">저장</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container> 
      <!-- 라이프맴핑_라이프맵핑 저장 E -->

      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="1000"></mo-snackbar>

    </ur-box-container>
    <!-- 라이프맴핑 전체 E -->
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT711P",
    screenId: "MSPCT711P",
    components: {
      MspBottomSelect
    },
    modalProps: {
      mode: 'right', // 우->좌 open popup (defalut: 하->상)
      // full: true
    },
    props: {
      pCtLifeMappRsltInfoSVO: {
        type: Object,
        default:() =>{
          return {}
        }
      },
      aa: {
        type: String,
        default: ''
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      this.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.$props.pCtLifeMappRsltInfoSVO)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        dummy1 : '',
        ctLifeMappRsltInfoSVO : {},
        moveHomeYn : false,

        validateLifeMappFileNm: [
          v => !this.validYnLifeMappFileNm || '이미 동일한 파일명이 있으니 변경해주세요.',
          v => v.trim().length > 0 || '파일명을 입력해주세요.'
        ],

        validYnLifeMappFileNm : false
      };
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /**
       * Function명 : fn_Close
       * 설명       : 화면 close
       * @param {none} - N/A
       * @return {none} - 반환없음
       */
      fn_close() {
        this.$emit('onPopupClose')
      },
      fn_fileNmDupChk() {
        this.validYnLifeMappFileNm = false
        this.$refs.lifeMappFileNm.clearValidation()

        if(this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.lifeMappFileNm) || this.ctLifeMappRsltInfoSVO.lifeMappFileNm.trim().length == 0){
          this.$refs.lifeMappFileNm.validate()
        }else{

          this.ctLifeMappRsltInfoSVO.lifeMappFileNm = this.$bizUtil.replaceAll(this.ctLifeMappRsltInfoSVO.lifeMappFileNm, ' ', '')

          let userId = this.getStore('userInfo').getters.getUserInfo.userId
          let lv_vm = this
          let pParams =
                    { 
                      userEno: userId,
                      cTLifeMappRsltInfo : this.ctLifeMappRsltInfoSVO
                    }
  
          let trnstId = 'txTSSCT70S7' // 파일명중복검사
          console.log('============= 파일명중복검사 pParams  >> ', pParams)

          this.post(lv_vm, pParams, trnstId, 'S').then(function (response) {
            console.log('response ==> ', response)
            if ( response.body !== null && response.body !== '') {
              if(response.body.cnt > 0){
                lv_vm.validYnLifeMappFileNm = true
                lv_vm.$refs.lifeMappFileNm.validate()
                // lv_vm.$refs.snackbar.show({text: '동일한 파일명이 있으니 변경해주세요.'})
                // lv_vm.fn_BottomAlert('이미 동일한 파일명이 있으니 변경해주세요.')
              }else{
                if(lv_vm.ctLifeMappRsltInfoSVO.tmpFileYn == '1'){
                  lv_vm.fn_tmpSave()
                }else{
                  lv_vm.fn_save()
                }
              }
            }else{
              if(response.msgComm.msgCd == 'ECOU011'){
                let msg = response.msgComm.msgDesc + '은(는) 필수값입니다.'
                lv_vm.fn_BottomAlert (msg, '에러')
              }else{
                lv_vm.fn_BottomAlert (response.msgComm.msgDesc, '에러')
              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })

        }
      },
      fn_tmpSave() {
        let userId = this.getStore('userInfo').getters.getUserInfo.userId
        let lv_vm = this

        lv_vm.ctLifeMappRsltInfoSVO.tmpFileYn = '0' // 파일명 저장을 위해 임시여부 변경

        let pParams =
                  { 
                    userEno: userId,
                    cTLifeMappRsltInfo: lv_vm.ctLifeMappRsltInfoSVO
                  }
 
        let trnstId = 'txTSSCT70S4' // 라이프매핑결과 상세정보수정
        console.log('============= 라이프매핑결과 상세정보수정 pParams  >> ', pParams)

        this.post(lv_vm, pParams, trnstId, 'U').then(function (response) {
          console.log('response ==> ', response)
          if (response.msgComm.msgCd === 'EFWS003') {
            lv_vm.moveHomeYn = true
            lv_vm.fn_BottomAlert('저장되었습니다')
          }else{
            lv_vm.fn_BottomAlert('저장 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc )
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_save() {
        let userId = this.getStore('userInfo').getters.getUserInfo.userId
        let lv_vm = this
        lv_vm.ctLifeMappRsltInfoSVO.lifeMappFileId = '' // 신규저장

        let pParams =
                  { 
                    userEno: userId,
                    cTLifeMappRsltInfo: lv_vm.ctLifeMappRsltInfoSVO
                  }
 
        let trnstId = 'txTSSCT70S3' // 라이프매핑결과 상세정보저장
        console.log('============= 라이프매핑결과 상세정보저장 pParams  >> ', pParams)

        this.post(lv_vm, pParams, trnstId, 'I').then(function (response) {
          console.log('response ==> ', response)
          if (response.msgComm.msgCd === 'EFWS003') {
            lv_vm.moveHomeYn = true
            lv_vm.fn_BottomAlert('저장되었습니다')
          }else{
            lv_vm.fn_BottomAlert('저장 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc )
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
        * Function명 : fn_BottomAlert
        * 설명       : 알림 팝업 호출
      ******************************************************************************/
      fn_BottomAlert (contents, title) {
        title = this.$bizUtil.isEmpty(title) ? '알림' : title
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: title,
            content: contents
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              if(this.moveHomeYn){
                console.log(this.moveHomeYn)
                this.fn_close()
                this.$router.push( { name: 'MSPCT700M' } )
              }
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      }
      
    }
  };
</script>
<style scoped>
</style>