/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <ur-page-container title="라이프맵핑" :show-title="true" type="subpage" @on-header-left-click="fn_back">
    <template #frame-header-fixed>
      <ur-box-container direction="column" alignV="start" alignH="start" componentid="" class="nw-qw-wrap nw-life-wrap">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-area nw-life-result pal0"> 
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="nw-chart-area mal0">
            <div class="chart-area fex-clumn">
              <div class="chart-title-box fex-clumn">
                <div class="total-box fex-rw">
                  <div class="switch-box">
                    <span class="txt">리스크표시</span>
                    <mo-switch v-model="riskOnOff" class="ns-switch" labeled/>
                  </div>
                </div>
              </div>
              <div class="chart-table-type">
                  <!-- 은퇴전 차트 컬럼 S -->
                  <div class="table-culmn">
                    <strong class="culmn-tit">은퇴전</strong>
                  </div>
                  <!-- 은퇴전 차트 컬럼 S -->
                  <div class="table-culmn">
                    <strong class="culmn-tit">은퇴후</strong>
                  </div>
              </div>
            </div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
    </template>


    <!-- 라이프맴핑 전체 S -->
    <ur-box-container direction="column" alignV="start" alignH="start" componentid="" class="nw-qw-wrap nw-life-wrap pt0">
      <!-- 라이프맴핑 한눈에 보기 S -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-area nw-life-result pal0">        
        <!-- 라이프맴핑_차트 영역 S -->
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="nw-chart-area mal0">
          <div class="chart-area fex-clumn">
            <!-- 라이프맵핑 차트_유형 table S -->
            <div class="chart-table-type btnone">
              <!-- 은퇴전 차트 컬럼 S -->
              <div class="table-culmn">
                <div class="con-box">
                  <span class="total-sum"><span class="color2">총 소득</span> <b>{{ rtmBefTotIncm }}</b>억원</span>  
                  <!-- 소득 관련 그래프 차트 S -->
                  <div class="chart-graph-type h170px">
                    <div class="chart-item-box" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmBefMntBasIncmAmt)">
                      <div class="chart-item">
                        <strong class="tit">월소득</strong>
                      </div>
                    </div>
                    <!-- 리스크 영역 : 사망리스크 -->
                    <div class="chart-risk-box" v-show="riskOnOff">
                      <a href="#" class="risk-link" @click="fn_openPdf('E401')">
                        <span class="risk-txt">사망<br>리스크</span>
                      </a>
                    </div>
                  </div>

                  <!-- 수지차 S -->
                  <div class="chart-difference">
                    수치차 <b>{{ (Number(rtmBefTotIncm) - Number(rtmBefTotExpnt)).toFixed(1) }}</b>억원
                  </div>
                  <span class="total-sum"><span class="color3">총 지출</span> <b>{{ rtmBefTotExpnt }}</b>억원</span> 

                  <!-- 지출 그래프 차트 S -->
                  <div class="chart-graph-type chart-ty1">
                    <div class="chart-item-box h166px">
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmBefMntLvgct)">
                        <strong class="tit">생활비</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmBefLnPrnc)">
                        <strong class="tit">대출</strong>
                      </div>
                      <!-- 개별 항목: 2개씩 배열 (chart-item-half추가): 자녀교육,이벤트 S -->
                      <div class="chart-item-half fex-rw" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmBefChldEduCst)">
                        <!-- 개별 항목: 자녀교육 S -->
                        <div class="chart-item">
                          <strong class="tit">자녀교육</strong>   
                        </div>
                        <!-- 개별 항목: 자녀교육 E-->
                        <!-- 개별 항목: 이벤트 S -->
                        <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmBefEvtAmt)">
                          <strong class="tit">이벤트</strong>
                        </div>
                        <!-- 개별 항목: 이벤트 E-->
                      </div>
                      <!-- 개별 항목: 2개씩 배열 (chart-item-half추가): 자녀교육,이벤트 E -->
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmBefMntInsrSavAmt)">
                        <strong class="tit">보험료/저축</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmBefEtcExpntAmt)">
                        <strong class="tit">기타지출</strong>
                      </div>
                    </div>
                    <!-- 리스크 영역 : 질병 리스크  : class명 gray 추가시 빈값으로 표시 -->
                    <div class="chart-risk-box rsk-ty-b" v-show="riskOnOff">
                      <a href="#" class="risk-link" @click="fn_openPdf('E402')">
                        <span class="risk-txt">질병리스크</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
               <!-- 은퇴후 차트 컬럼 S -->
               <div class="table-culmn">
                <div class="con-box">
                  <span class="total-sum"><span class="color2">총 소득</span> <b>{{ rtmAftTotIncm }}</b>억원</span>  
                   <!-- 월소득 그래프 차트 S -->
                  <div class="chart-graph-type h170px">
                    <div class="chart-item-box">
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmAftMntBasIncmAmt)">
                        <strong class="tit">월소득</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmAftMntPanutAmt)">
                        <strong class="tit">국민연금</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmAftMntNpensAmt)">
                        <strong class="tit">개인연금</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.exptTotRtmAmt)">
                        <strong class="tit">퇴직금</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmAftAddmMntIncmAmt)">
                        <strong class="tit">추가소득</strong>
                      </div>
                    </div>
                     <!-- 리스크 영역 : 소득리스크 -->
                    <div class="chart-risk-box" v-show="riskOnOff">
                      <a href="#" class="risk-link" @click="fn_openPdf('E404')">
                        <span class="risk-txt">소득<br>리스크</span>
                      </a>
                    </div>
                  </div>

                  <!-- 수지차 S -->
                  <div class="chart-difference">
                    수치차 <b>{{ (Number(rtmAftTotIncm) - Number(rtmAftTotExpnt)).toFixed(1) }}</b>억원
                  </div>
                  <span class="total-sum"><span class="color3">총 지출</span> <b>{{ rtmAftTotExpnt }}</b>억원</span> 

                   <!-- 지출 그래프 차트 S -->
                  <div class="chart-graph-type chart-ty1">
                    <div class="chart-item-box">
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmAftMntLvgct)">
                        <strong class="tit">생활비</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmAftChldMargCst)">
                        <strong class="tit">자녀결혼</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmAftHlthPrm)">
                        <strong class="tit">의료비</strong>
                      </div>
                      <div class="chart-item" v-if="!$bizUtil.isEmpty(ctLifeMappRsltInfoSVO.rtmAftMntMdexp)">
                        <strong class="tit">건강보험료</strong>
                      </div>
                    </div>
                    <!-- 리스크 영역 : type -->
                    <div class="chart-risk-box rsk-ty-b h65px" v-show="riskOnOff">
                      <a href="#" class="risk-link" @click="fn_openPdf('E403')">
                        <span class="risk-txt">의료/간병비 리스크</span>
                      </a>
                    </div>
                    <!-- 리스크 영역 : 의료/간병비 리스크 : class명 gray 추가시 빈값으로 표시 -->
                    <!-- <div class="chart-risk-box rsk-ty-b h65px gray">
                      <a href="#" class="risk-link">
                        <span class="risk-txt">의료/간병비 리스크</span>
                      </a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_fofPbl">지점발행</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_save">저장</mo-button>
          </div>
        </ur-box-container>
        <div class="ns-bottom-sheet ty1">   
          <mo-bottom-sheet ref="save">
            <div class="untact-pop-box mt30 mb50">
              <p class="untact-txt1 txt-center">
                불러왔던 기존 파일에 덮어쓸까요?
              </p>
            </div>
            <template v-slot:action>
              <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
                <div class="relative-div">
                  <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_OpenMSPCT711P" class="ns-btn-round white">신규저장</mo-button>
                  <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_fileUpdate" class="ns-btn-round blue">덮어쓰기</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>
        </div>
      </ur-box-container>
      <!-- 라이프맴핑 한눈에 보기 E --> 

    </ur-box-container>
    <!-- 라이프맴핑 전체 E--> 
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import CtConstants from '@/config/constants/ctConstants'
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPCT711P from '@/ui/ct/MSPCT711P' // 파일저장

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT709M",
    screenId: "MSPCT709M",
    components: {
      MspBottomSelect
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      console.log('==== this.$route.params >> ', this.$route.params)
      this.stepButton = this.$route.params.step
      this.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.$route.params.ctLifeMappRsltInfoSVO)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        firstItem: '1',
        value: '',
        stepButton: '',
        riskOnOff: false,
        ctLifeMappRsltInfoSVO: {},

        // rtmBefTotIncm: 0, //은퇴전총소득
        // rtmAftTotIncm: 0, //은퇴후총소득
        // rtmBefTotExpnt: 0, //은퇴전총지출
        // rtmAftTotExpnt: 0, //은퇴후총지출
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // 은퇴전총소득
      // ((월기본소득 + 월추가소득) * 12 * (은퇴나이 - 현재나이)) * 0.0001
      rtmBefTotIncm() {
        return (((Number(this.ctLifeMappRsltInfoSVO.rtmBefMntBasIncmAmt) + Number(this.ctLifeMappRsltInfoSVO.rtmBefMntAddmIncmAmt)) * 12 * 
          (Number(this.ctLifeMappRsltInfoSVO.custRtmAge) - Number(this.ctLifeMappRsltInfoSVO.custCurAge))
          ) * 0.0001).toFixed(1)
      },
      // 은퇴전총지출
      // ((월생활비 + 월상환액 + 월보험저축) * 12 * (은퇴나이-현재나이) + 기타지출 + (대출금 + (자녀교육*인원) + 이벤트용) * 1000) * 0.0001
      rtmBefTotExpnt() {
        return (((Number(this.ctLifeMappRsltInfoSVO.rtmBefMntLvgct) + Number(this.ctLifeMappRsltInfoSVO.rtmBefLnMntRedam) + Number(this.ctLifeMappRsltInfoSVO.rtmBefMntInsrSavAmt)) * 12
          * (Number(this.ctLifeMappRsltInfoSVO.custRtmAge) - Number(this.ctLifeMappRsltInfoSVO.custCurAge)) + Number(this.ctLifeMappRsltInfoSVO.rtmBefEtcExpntAmt)
          + (Number(this.ctLifeMappRsltInfoSVO.rtmBefLnPrnc) + (Number(this.ctLifeMappRsltInfoSVO.rtmBefChldEduCst) * Number(this.ctLifeMappRsltInfoSVO.curChldFgr)) + Number(this.ctLifeMappRsltInfoSVO.rtmBefEvtAmt))
          * 10000) * 0.0001).toFixed(1)
      },
      // 은퇴후총소득
      // ((월소득 * 12 * 년수) + (월국민연금 + 월개인연금 + 월추가소득) * 12 * (예상수명 - 65) + 총퇴직금) * 0.0001
      rtmAftTotIncm() {
        return (((Number(this.ctLifeMappRsltInfoSVO.rtmAftMntBasIncmAmt) * 12 * Number(this.ctLifeMappRsltInfoSVO.rtmAftMntBasIncmPrd))
          + (Number(this.ctLifeMappRsltInfoSVO.rtmAftMntPanutAmt) + Number(this.ctLifeMappRsltInfoSVO.rtmAftMntNpensAmt) + Number(this.ctLifeMappRsltInfoSVO.rtmAftAddmMntIncmAmt)) * 12
          * (Number(this.ctLifeMappRsltInfoSVO.custExptLolAge) - 65) + Number(this.ctLifeMappRsltInfoSVO.exptTotRtmAmt)
          ) * 0.0001).toFixed(1)
      }, 
      // 은퇴후총지출
      // ((월생활비 + 월의료비 + 건보료) * 12 * (예상수명 - 은퇴나이) + (자녀결혼인당 * 명수) + 기타지출) * 0.0001
      rtmAftTotExpnt() {
        return (((Number(this.ctLifeMappRsltInfoSVO.rtmAftMntLvgct) + Number(this.ctLifeMappRsltInfoSVO.rtmAftHlthPrm) + Number(this.ctLifeMappRsltInfoSVO.rtmAftMntMdexp)) * 12
          * (Number(this.ctLifeMappRsltInfoSVO.custExptLolAge) - Number(this.ctLifeMappRsltInfoSVO.custRtmAge))
          + (Number(this.ctLifeMappRsltInfoSVO.rtmAftChldMargCst) * Number(this.ctLifeMappRsltInfoSVO.curChldFgr)) + Number(this.ctLifeMappRsltInfoSVO.rtmAftEtcExpntAmt)
          ) * 0.0001).toFixed(1)
      }

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_back(){
        let objParams = {}
        objParams.step = this.stepButton
        objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.ctLifeMappRsltInfoSVO)
        
        this.$router.push( { name: 'MSPCT704M', params : objParams} )
      },
      fn_save() {
        let lifeMappFileId = this.ctLifeMappRsltInfoSVO.lifeMappFileId
        let tmpFileYn = this.ctLifeMappRsltInfoSVO.tmpFileYn
console.log('lifeMappFileId >> ', lifeMappFileId)
console.log('tmpFileYn >> ', tmpFileYn)
        if(this.$bizUtil.isEmpty(lifeMappFileId)){
          this.fn_OpenMSPCT711P()
        }else{
          if(tmpFileYn == '1'){
            this.fn_OpenMSPCT711P()
          }else{
            this.$refs.save.open()
          }
        }
      },
      /**
       * Function명 : fn_OpenMSPCT711P
       * 설명       : 파일신규저장
       */
      fn_OpenMSPCT711P () {
        this.popupCT711P = this.$bottomModal.open(MSPCT711P, {
          properties: {
            pCtLifeMappRsltInfoSVO: this.ctLifeMappRsltInfoSVO,
          },
          listeners: {
            onPopupClose: () => {
              this.$bottomModal.close(this.popupCT711P)
            }
          }
        })
      },
      fn_fileUpdate() {
        console.log('==== fn_fileUpdate')
        let userId = this.getStore('userInfo').getters.getUserInfo.userId
        let lv_Vm = this

        let pParams =
                  { 
                    userEno: userId,
                    cTLifeMappRsltInfo: lv_Vm.ctLifeMappRsltInfoSVO
                  }
 
        let trnstId = 'txTSSCT70S4' // 라이프매핑결과 상세정보수정
        console.log('============= pParams  >> ', pParams)

        this.post(lv_Vm, pParams, trnstId, 'U').then(function (response) {
          console.log('response ==> ', response)
          if (response.msgComm.msgCd === 'EFWS003') {
            lv_Vm.fn_BottomAlert('저장되었습니다')
          }else{
            lv_Vm.fn_BottomAlert('저장 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc )
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
        * Function명 : fn_BottomAlert
        * 설명       : 알림 팝업 호출
      ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '',
            content: contents
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              this.$router.push( { name: 'MSPCT700M' } )
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      fn_openPdf(untySlmtClsfCd) {
        let lv_vm = this
        let pParams =
        {
          untySlmtClsfCd: untySlmtClsfCd,
          untySlmtUseAuthCd: '1%', // 통합영업자료사용권한코드,기본값은 FC // 통합영업자료사용권한코드(전체/FC/AM/LT/GFC/방카)
          moblInqrYn: 'Y' // 모바일에서 호출여부
        }

        // 트랜잭션ID 설정
        let trnstId = 'txTSSSA09S1'
        const _auth = 'S'

        this.post(lv_vm, pParams, trnstId, _auth)
          .then(function (response) {
            if (lv_vm.$commonUtil.isSuccess(response)) {
              console.log('========== fn_openPdf =================', response)
              // 서비스 후처리
              if (response.body !== null && response.body !== '') {
                // 서비스 호출 결과(body)가 있을 경우
                // 응답 데이터 처리
                let reponseBody = response.body
                if(reponseBody.iCCntntBasInfoVO.length > 0){
                  lv_vm.lv_SelectedSingleItem = reponseBody.iCCntntBasInfoVO[0]
                  lv_vm.fn_SaveFOMFile()
                }else{
                  lv_vm.$addSnackbar('PDF 파일이 존재하지 않습니다.')
                }
                
              } else {
                // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              }
            } else {
              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }, function (error) {
            // 서버 에러 처리
            return Promise.reject(error)
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_BtnESaveFile
      * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
      ******************************************************************************/
      fn_SaveFOMFile () {
        console.log('================== process.env.NODE_ENV >> ', process.env.NODE_ENV)
        // NAS에 저장된 PDF 파일 다운로드 시도 후 성공횟수
        // let successCount = 0
        // NAS에 저장된 PDF 파일 다운로드 시도 횟수
        // let tryCount = 0
        // NAS에 저장된 PDF 파일을 다운받기 위한 정보를 저장하기 위한 컬렉션
        // PDF Viewer 실행 전 Native 저장소로 다운로드할 PDF 파일 정보를 저장하는 Array
        // FOM 파일의 경우는 문서변환에 의해 변환된 정보를 담고 있는 VO를 이용
        let savePdfTargetItemList = []
        // PDF Viewer Native에 전달한 파일 목록을 저장하는 변수
        // test.pdf|[테스트],test2.pdf|[테스트2] 와 같은 문자열로 전달
        // test.pdf|[테스트] 와 같이 단일 파일을 보여줄 경우에도 뒤에 ,를 붙여서 파라미터로 전달
        // PDF Viewer를 통해 실행할 파일 목록과 파일 제목 목록을 저장하는 변수 초기화
        // 파일명tssB파일제복tssA파일명2tssB파일제목2tssA와 같은 형식으로 목록 구성
        let viewerTargetFileList = ''

        let selectedUntySlmtItem = this.lv_SelectedSingleItem
        // alert('분류목록결과:::selectedUntySlmtItem 셋팅' + JSON.stringify(selectedUntySlmtItem))
        // 선택자료 유형이 PDF일 경우
        if (selectedUntySlmtItem.pdfFileYn === 'Y') {
          let itemObj = {}
          itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
          itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
          itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
          itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

          savePdfTargetItemList.push(itemObj)
        }
        // Native PDF Viewer 호출 전 PDF 파일을 Native 저장공간(/sli/tss/comm/fmlmt)에 다운로드
        savePdfTargetItemList.forEach(item => {
          if (CtConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSA001M ■■■■■ fn_ChkDocsTrfo savePdfTargetItemList"s item=' + item.eltrnDoctFileNm)
          if (process.env.NODE_ENV === 'development') {
            item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            item.eltrnDoctFileNm = 'test.pdf'
            item.localFileNm = 'test.pdf'
            item.fileTitle = '[TEST]'
          }
          try {
            this.fn_FdpSaveFile(item)
              .then(response => {
                // 파일명과 PDF Viewer에 노출될 제목을 '|'로 결합하여 한 파일에 대한 정보를 구성
                // viewerTargetFileList += response + '|' + item.fileTitle + ','
                viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
                // viewerTargetFileList += response
                // 단일 선택일 경우는 바로 PDF Viewer 호출
                this.fn_CallPdfViewer(viewerTargetFileList)
              }).catch(error => {
                // 다중 선택일 경우
                // 화면 깜박거림을 방지하기 위한 로딩바를 해제
                this.getStore('progress').getters.getState.isShow = false
                // 오류 팝업 메시지 출력
                this.getStore('confirm').dispatch('ADD', '총 1건 중 1건 저장이 실패하였습니다')
                if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
              })
          } catch (error) {
            if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })

      },
      /******************************************************************************
      * Function명 : fn_FdpSaveFile
      * 설명        : Native 저장 장소로 실제 PDF 파일을 다운로드
      *              실제 파일 존재 유무는 다음과 같은 URL을 이용 확인 가능
      *              https://vtss.samsunglife.com:8410/filedown.do?path=file.path.fmlmt&filename=/20190122/000300336200252107122109102.pdf&type=pdf
      * Params     : 다운로드할 PDF 정보를 담고있는 개체
      * Return     : N/A
      ******************************************************************************/
      fn_FdpSaveFile (obj) {
        return new Promise((resolve, reject) => {
          // let tempFileName = obj.eltrnDoctFileNm
          // .pdf 확장자가 없을 경우 확장자 첨부
          if (obj.localFileNm.indexOf('.pdf') < 0) {
            obj.localFileNm = obj.localFileNm + '.pdf'
          }
          console.log(' down plugin : ' + this.$commonUtil.isMobile())
          if (this.$commonUtil.isMobile()) {
            window.fdpbridge.exec('downloadPlugin', {
              path: obj.eltrnDoctFilePathNm,
              filename: obj.eltrnDoctFileNm, // src Directory + fileName
              type: 'pdf',
              localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
              localfilename: obj.localFileNm, // local fileName
              rename: 'N' // 파일명 중복에 관계없이 신규 파일 생성 여부
            }, (result) => {
              resolve(result.data)
            }, (result) => {
              reject(result.data)
            })
          } else { // 데스크탑 테스트 용도
            let t_Url = ''
            if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage') {
              t_Url = 'http://u-channel.v.samsunglife.kr:9000/BRCH_ATTACH/bizupload/' + obj.eltrnDoctFileNm + '?IS_POPUP=true'
            } else {
              t_Url = 'https://tss.samsunglife.com:8410/filedown.do?' + 'path=' + obj.eltrnDoctFilePathNm + '&filename=' + obj.eltrnDoctFileNm + '&type=pdf'
            }

            console.log(' t_Url : ' + t_Url)
            console.log(' filename : ' + obj.eltrnDoctFileNm)
            // let timeer = setInterval(window.open(t_Url, '_blank'), 5000)
            // clearInterval(timeer)
            window.open(t_Url, '_blank')
            // lv_vm.fn_getEncrKey() // getEncrKey 로컬에서는 암호화키 파일삭제 패스
            // lv_vm.fn_PdfViewerPlugin(lv_vm.fNInsrFnEltrnSignDoctVO) // 4.전자서명 뷰어 실행
          }
        })
      },
      fn_CallPdfViewer (viewerTargetFileList) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        this.getStore('progress').getters.getState.isShow = false
        let menuAuth = '00000'
        let lv_vm = this
        
        // PDF 뷰어 호출
        window.fdpbridge.exec('callPdfViewerPlugin', {
          path: '/sli/tss/comm/fmlmt',
          menu: menuAuth, // 대메뉴가 "라이브러리" or "사랑On도움방" 일때 안드로이드 앱의 pdf 뷰's "SMS" 메뉴는 않보이게 한다.
          file: viewerTargetFileList // 서버에 올릴때.
          // file: 'EST_20th.pdftssB제목1tssAtest1234567890.pdftssB제목2tssAtest.pdftssB제목3tssAEST_20th2.pdftssB제목4tssAtest-123.pdftssB제목5tssAEST_20th012345678901234567890123456789.pdftssB제목5tssA' // 테스트
        }, function (result) {
          if (SpConstants.DEBUG_MODE_CON) console.log('성공콜백=', result)
          // // 성공콜백
          // // 스마트폰은 SMS 팝업창을 바로 호출하자. 이때 btn1 이고 pdf뷰의 'X'표시를 클릭시는 'cancel' 값이 온다.
          // if (result.data === 'btn1') {
          //   //this.lv_IsSelectedLibraryContent === true 체크해서 SMS발송 막아야함 pk.son
          //   // if (lv_vm.lv_IsSearch) lv_vm.fn_SMSBtnClicked('TSSSP205M')
          //   lv_vm.fn_SMSBtnClicked()
          // }
        }, function (result) {
          // 실패콜백
          if (SpConstants.DEBUG_MODE_CON) console.log('실패콜백=' + result.data)
        })
      },
      fn_fofPbl() {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            // single: true,
            title: '',
            content: '라이프맵핑 결과를 발행하시겠습니까?',
            title_pos_btn: "발행",
            title_neg_btn: "취소"
          },
          listeners: {
            onPopupConfirm: () => {
              // console.log("000 pParams:::", pParams)
              this.$bottomModal.close(this.lv_AlertPop)

              let lv_Vm = this
              let pParams = {
                userEno: this.getStore('userInfo').getters.getUserInfo.userId,
                cTLifeMappRsltInfo : this.ctLifeMappRsltInfoSVO
              }
              const trnstId = 'txTSSCT70S2'
              const auth = 'S'
              // console.log("000 pParams:::", pParams)
              this.post(lv_Vm, pParams, trnstId, auth)
                .then(function(response) {
                  // console.log("000 response:::", response)
                  // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
                  if (lv_Vm.$commonUtil.isSuccess(response)) {
                    if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                      // 고객명마스킹 필드 셋팅
                      lv_Vm.ctLifeMappRsltInfoSVO.mobslChnlCustNmMskng = response.body.cTLifeMappRsltInfo.mobslChnlCustNmMskng
                      lv_Vm.fn_SelCnsltInfo()
                    }
                  }
                })
                .catch(function(error) {
                  window.vue.error(error)
                })
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_SelCnsltInfo
       * 설명       : 컨설턴트 정보 조회(전문 생성을 위해)
       ******************************************************************************/
      fn_SelCnsltInfo() {
        let lv_Vm = this
        if (lv_Vm.getStore('progress').getters.getState.isShow !== null && lv_Vm.getStore('progress').getters.getState.isShow === false) {
          lv_Vm.getStore('progress').getters.getState.isShow = true
        }
        
        let pParams = {
          userEno: this.getStore('userInfo').getters.getUserInfo.userId
        }
        const trnstId = 'txTSSBC20S3'
        const auth = 'S'
        // console.log("pParams:::", pParams)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function(response) {
            // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            // console.log("response:::", response)
            if (lv_Vm.$commonUtil.isSuccess(response)) {
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                lv_Vm.fn_OzPrintService(response.body) // [보험거래문서디지털화PJT] OZ 지점발행 추가
              }
            }
          })
          .catch(function(error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
       * Function명 : fn_OzPrintService
       * [보험거래문서디지털화PJT] OZ 출력 서비스 추가
       * 설명       : OZ 인쇄 서비스 호출
       ******************************************************************************/
      fn_OzPrintService(cnsltInfo) {
        var lv_Vm = this
        let trnstId = 'txTSSCM11P5'
        const auth = 'S'
        // let lv_NewFomName = savePdfTargetItem.srcFileNm  TB_ICATM00에 없는 필드
        let prtCntntSVO = {}
        prtCntntSVO.cnsltNo = cnsltInfo.userEno // 컨설턴트번호
        prtCntntSVO.fofNo = cnsltInfo.fofOrgNo // 지점조직번호
        prtCntntSVO.pblSvrSno = '0' // 발행일련번호
        if (process.env.NODE_ENV === 'local') {
          prtCntntSVO.pblSvrIp = '100.119.81.226' // 발행서버IP
        } else if (process.env.NODE_ENV === 'development') {
          prtCntntSVO.pblSvrIp = '100.119.81.226' // 발행서버IP
        } else {
          prtCntntSVO.pblSvrIp = userInfo.pblSvrIp // 발행서버IP
        }
        // prtCntntSVO.pblSvrIp = '100.1.92.239' // 발행서버IP (ci 테스트용)
        prtCntntSVO.cnsltNm = cnsltInfo.userNm // 컨설턴트명
        prtCntntSVO.chnlScCd = cnsltInfo.chnlScCd // 채널구분코드
        prtCntntSVO.custNm = this.ctLifeMappRsltInfoSVO.mobslChnlCustNmMskng //고객명
        prtCntntSVO.mnCustNm = this.ctLifeMappRsltInfoSVO.mobslChnlCustNmMskng //고객명
        lv_Vm.jB = 1
        // var tlgmCntnt = ''
        // console.log('>>> 11111: ' + prtCntntSVO)
        prtCntntSVO.srcFileNm01 = 'lifemapp' // lv_Vm.lv_NewFomName 원천화일명
        let iCCntntCustInfoVO01 = []
        let mOICCntntCustInfoVO = {}
        let iCCntntCustInfoVO02 = []
        let mOICCntntCustInfoVO2 = {}
        mOICCntntCustInfoVO.tlgmCntnt = decodeURIComponent(lv_Vm.fn_GetOzDDS(cnsltInfo, prtCntntSVO)) // 전문
        // console.log('>>> 2222: ' + mOICCntntCustInfoVO.tlgmCntnt)
        mOICCntntCustInfoVO.custNm = this.ctLifeMappRsltInfoSVO.mobslChnlCustNmMskng  // 고객명
        mOICCntntCustInfoVO.chnlCustId = '' // 채널고객ID
        mOICCntntCustInfoVO.rrn = '' // 주민등록번호
        iCCntntCustInfoVO01.push(mOICCntntCustInfoVO)
        prtCntntSVO.iCCntntCustInfoVO01 = iCCntntCustInfoVO01
       
        //tlgmCntnt += ']'
        // ,"PBL_DFNT":{"fcname":"' + lv_Vm.userInfo.userNm + '","fccode":"' + lv_Vm.userInfo.userEno + '","tbtpblkindnm": "","title":"라벨","merge":"Y","entplid": "","tbtpblno": "","channel": "","outgoing": ""}}
        // while ( lv_Vm.jB <= lv_Vm.selectItems.length ) {
          
        prtCntntSVO.bfPrntYn = 'N' // 양면인쇄여부
        prtCntntSVO.pblNos01 = 1 // 발행매수
        prtCntntSVO.chnlScCd = cnsltInfo.chnlScCd // 채널구분코드
        prtCntntSVO.prdtNm = '라이프매핑'
        prtCntntSVO.bfPrntYn = 'N' // 양면인쇄여부
        prtCntntSVO.doctStoreExnex = 'N' // PDF전환유무
        prtCntntSVO.untySlmtId = '*'
        prtCntntSVO.trnsScCd = '20'
        
        prtCntntSVO.iCCntntCustInfoVO02 = iCCntntCustInfoVO02

        console.log('>>> 7777: ' + prtCntntSVO)

        lv_Vm.post(lv_Vm, prtCntntSVO, trnstId, auth)
          .then(function (response) {
            //console.log('■■■■■ labelform ■■■■■ axios.post lv_Vm.$commonUtil.isSuccess(response)=' + lv_Vm.$commonUtil.isSuccess(response))
            if ( lv_Vm.$commonUtil.isSuccess(response) ) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
              // 서비스 후처리
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                //console.log('■■■■■ labelform ■■■■■ lv_UntySlmtItemPrintCount= 1')
                lv_Vm.getStore('confirm').dispatch('ADD', '출력 되었습니다.')
              } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
                lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            } else {
              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
            // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('CM')['error_message'])
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /*******************************************************************************
       * Function명: fn_GetOzDDS
       * [보험거래문서디지털화PJT] 추가
       * 설명: OZ 전문 생성
       * { "FORM_ARY":[{"formId":""}],
       *   "DATA_ARY":[{"SingleValueMap":{}}],
       *   "META_ARY":[{}],
       *   "PBL_DFNT":{}
       * }
       *******************************************************************************/
      fn_GetOzDDS(cnsltInfo, prtCntntSVO) {
        var sData = ''
        // FORM_ARY
        sData += '{"FORM_ARY":[{"formId":"/IC/lifemapp"}]'
        sData += ',"DATA_ARY":[{"SingleValueMap":{'        

        // console.log('============= this.ctLifeMappRsltInfoSVO  >> ', this.ctLifeMappRsltInfoSVO)
        sData += '"CUST_NM":"' + this.ctLifeMappRsltInfoSVO.mobslChnlCustNmMskng + '"'  //고객명마스킹
        sData += ',"IB1":"' + this.ctLifeMappRsltInfoSVO.rtmBefMntBasIncmAmt + '"'    //퇴직전원소득금액(만원)
        sData += ',"IB2":"' + (Number(this.ctLifeMappRsltInfoSVO.custRtmAge) - Number(this.ctLifeMappRsltInfoSVO.custCurAge)) + '"'  //퇴직전소득기간(년)
        sData += ',"IB3":"' + this.rtmBefTotIncm + '"' //퇴직전총소득금액(억원)
        sData += ',"IA1":"' + this.ctLifeMappRsltInfoSVO.rtmAftMntBasIncmAmt + '"'  //퇴직후월소득금액(만원)
        sData += ',"IA2":"' + this.ctLifeMappRsltInfoSVO.rtmAftMntBasIncmPrd + '"' //퇴직후소득기간(년)
        sData += ',"IA3":"' + '0"'//+ this.ctLifeMappRsltInfoSVO.bizNum + '"'   //퇴직후총소득금액(억원)
        sData += ',"IA4":"' + this.rtmAftTotIncm + '"'//퇴직후국민연금소득금액(만원)
        sData += ',"IA5":"' + this.ctLifeMappRsltInfoSVO.rtmAftMntNpensAmt + '"'  //퇴직후개인연금소득금액(만원)
        sData += ',"IA6":"' + this.ctLifeMappRsltInfoSVO.exptTotRtmAmt + '"'  //퇴직후퇴직금(만원)
        sData += ',"OB1":"' + this.ctLifeMappRsltInfoSVO.rtmAftMntLvgct + '"'  //퇴직전월생활비
        sData += ',"OB2":"' + (Number(this.ctLifeMappRsltInfoSVO.custRtmAge) - Number(this.ctLifeMappRsltInfoSVO.custCurAge)) + '"'  //퇴직전지출기간(년)
        sData += ',"OB3":"' + this.rtmBefTotExpnt + '"'  //퇴직전총지출금액(억원)
        sData += ',"OB4":"' + this.ctLifeMappRsltInfoSVO.rtmBefLnPrnc + '"'  //대출금액
        sData += ',"OB5":"' + this.ctLifeMappRsltInfoSVO.rtmBefLnMntRedam + '"'  //월대출상환액
        sData += ',"OB6":"' + this.ctLifeMappRsltInfoSVO.rtmBefChldEduCst + '"'  //퇴직전자녀교율자금(만원)
        sData += ',"OB7":"' + this.ctLifeMappRsltInfoSVO.rtmBefEvtAmt + '"'  //퇴직전이벤트금액(원)
        sData += ',"OB8":"' + this.ctLifeMappRsltInfoSVO.rtmBefMntInsrSavAmt + '"'  //퇴직전월저축금액(만원)
        sData += ',"OB9":"' + this.rtmBefTotExpnt + '"'  //퇴직전수지차(만원)
        sData += ',"OA1":"' + this.ctLifeMappRsltInfoSVO.rtmAftMntLvgct + '"'  //퇴직후월생활비
        sData += ',"OA2":"' + (Number(this.ctLifeMappRsltInfoSVO.custExptLolAge) - Number(this.ctLifeMappRsltInfoSVO.custRtmAge)) + '"'  //퇴직후지출기간(년)
        sData += ',"OA3":"' + this.rtmAftTotExpnt + '"'  //퇴직후총지출금액(억원)
        sData += ',"OA4":"' + this.ctLifeMappRsltInfoSVO.rtmAftChldMargCst + '"'  //퇴직후자녀결혼비용
        sData += ',"OA5":"' + this.ctLifeMappRsltInfoSVO.curChldFgr + '"'  //현재자녀수
        sData += ',"OA6":"' + (Number(this.ctLifeMappRsltInfoSVO.rtmAftChldMargCst) * Number(this.ctLifeMappRsltInfoSVO.curChldFgr))  + '"'  //총자녀결혼비용
        sData += ',"OA7":"' + this.ctLifeMappRsltInfoSVO.rtmAftHlthPrm + '"'  //퇴직후월의료비
        sData += ',"OA8":"' + this.ctLifeMappRsltInfoSVO.rtmAftMntMdexp + '"'                //퇴직후건보료
        sData += ',"OA9":"' + this.rtmAftTotIncm + '"' //퇴직후총소득
        sData += ',"age1":"' + this.ctLifeMappRsltInfoSVO.custCurAge + '"'
        sData += ',"age2":"' + this.ctLifeMappRsltInfoSVO.custRtmAge + '"'
        sData += ',"age3":"' + this.ctLifeMappRsltInfoSVO.custExptLolAge + '"'
        sData += '}}]' // DATA_ARY END
        
        /*******************************************************************************
         * 프린트 or 이메일 메뉴 선택 시 세팅하는 한글자이미지명
         * as-is는 프린트와 이메일 분기처리하여 구성이 상이한 전문을 생성
         * to-be는 동일한 전문을 구성하므로 분기처리 없이 로직 구현
         *******************************************************************************/
        
        sData += ',"META_ARY":[{"formname":"lifemapp","title":"라이프매핑","color":"C","duplex":"1","WTMK_NO":""}]'
        

        //console.log('## sData: ', sData)
        return sData
      }


    }
  }
</script>
<style scoped>

</style>


